import React, { useContext, useState } from 'react';
import renderHTML from 'react-render-html';
import { AuthContext } from '../../contexts/AuthContext';
import { ContentContext } from '../../contexts/ContentContext';
import ContentEditor from './ContentEditor';
import ContentDisplay from './ContentDisplay';
import { useMountEffect } from '../functions';

const Content = (props) => {
  const { page, item, itemIndex, type } = props;
  const { authUser } = useContext(AuthContext);

  return (authUser ?
    <ContentEditor
      page={page}
      item={item}
      itemIndex={itemIndex}
      type={type} />
    : <ContentDisplay
        page={page}
        item={item}
        itemIndex={itemIndex}
        type={type} />
  )
}

export default Content;