import React, { useContext, useState } from 'react';
import renderHTML from 'react-render-html';
import { ContentContext } from '../../contexts/ContentContext';
import { useMountEffect } from '../functions';

const ContentDisplay = (props) => {
  const [content, setContent] = useState([]);
  const { siteContent } = useContext(ContentContext);

  useMountEffect(() => {
    const { page, item, itemIndex, type } = props;
    const content = siteContent && siteContent[page] && siteContent[page][item] && siteContent[page][item][itemIndex] ? siteContent[page][item][itemIndex][type] : siteContent[page][item][type] ? siteContent[page][item][type] : [];
    setContent(content);
  });

  return (
    content && content.length > 0 ?
      <div className="content-text">
        {renderHTML(content)}
      </div>
    : null
  )
}

export default ContentDisplay;