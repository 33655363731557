import React, { useContext, useState, useEffect, useRef } from 'react';
import { db, firebase } from '../../firebase';
import { AuthContext } from '../../contexts/AuthContext';
import { ContentContext } from '../../contexts/ContentContext';
import { useMountEffect } from '../functions';
import Dropzone from 'react-dropzone';


const ContentImg = (props) => {

  const { page, item, itemIndex, type } = props;

  const [content, setContent] = useState([]);
  const [contentID, setContentID] = useState(null);
  const [editing, setEditing] = useState(false);
  const [imgStatus, setImgStatus] = useState('');
  const [error, setError] = useState(null);
  const imgUploads = useRef([]);

  const { authUser } = useContext(AuthContext);
  const { siteContent, setSiteContent } = useContext(ContentContext);

  useEffect(() => {
    if (siteContent && siteContent[page] && siteContent[page][item] && siteContent[page] !== content[page]) {
      if (itemIndex >= 0) {
        setContent(siteContent[page][item][itemIndex][type]);
        setContentID(siteContent[page][item][itemIndex].id);
      } else {
        setContent(siteContent[page][item][type]);
      }
    }
    return () => {
      imgUploads.current.forEach((upload) => upload.cancel());
    }
  }, [siteContent]);

  const saveContent = (file) => {
 
    setImgStatus('uploading');

    // This getContent lookup is not necessary but...
    // Just trying to make sure that all the other content is up-to-date so things don't get overwritten if two people are editing
    db.getContent().then((siteContent) => {
      const update = siteContent.val();
      let contentImage = content && content.length > 0 ? firebase.storage.refFromURL(content) : null;
      if (contentImage) { 
        contentImage.delete().then(() => {
          saveImage(update, file);   
        }); 
      } else {
        saveImage(update, file); 
      }       
    });
  };

  const saveImage = (update, file) => {
    let storageRef = firebase.storage.ref('content');
    const uploadTask = storageRef.child(`${page}/${item}/${contentID ? contentID : type}/${file[0].name}`).put(file[0]);
    imgUploads.current.push(uploadTask);
    uploadTask.on('state_changed', (snapshot) => {
      if (snapshot.bytesTransferred === snapshot.totalBytes) {
        console.log('Save Complete');
      }
    }, (error) => {
      console.log('Upload error: ', error);
      setError(error);
    }, () => {
      if (itemIndex >= 0) {
        db.getPostIndex(page, item, contentID).then((res) => {
          const postIndex = Object.keys(res.val())[0];
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            update[page][item][postIndex].img = downloadURL;
            updateDB(`${item}/${postIndex}`, downloadURL, update);
          });
        });
      } else {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          update[page][item][type] = downloadURL;
          updateDB(`${item}`, downloadURL, update);
        });
      }
    });
  };

  const updateDB = (path, content, update) => {
    db.saveContent(page, path, { img: content }).then(() => {
      setSiteContent(update);
      setEditing('');
      setImgStatus('');
      imgUploads.current = [];
    });
  };

  return (
    (authUser && content && content.length >= 0) || (authUser && !content)  ?
      <div className="content-editor-wrap">
        <span className={`content-editor content-img ${editing ? 'active' : ''}`} onClick={() => setEditing(!editing)}>
          { content && content.length > 0 ?
            <img src={content} alt={item} />
            : <i className="icon icon-image" />
          }
          <span className="content-edit">
            <Dropzone 
              style={{}}
              multiple={false}
              disabled={imgStatus !== ''}
              className='content-edit-img-dropzone files-dropzone'
              activeClassName="active"
              onDrop={(file) => saveContent(file)}>
              <div className="content-img-drop files-drop">
                <div className="btn btn-success" disabled={imgStatus !== ''}>
                  { imgStatus === 'uploading' ?
                    <span className="btn-loading" />
                    : imgStatus === 'complete' ?
                      'upload complete!'
                    : 'click to upload'
                  }
                </div>
              </div>
            </Dropzone>
          </span>
          <button className="btn btn-white content-cancel" onClick={(event) => {
            event.stopPropagation();
            setEditing(false);
          }}>
            <i className="icon icon-cross" />
          </button>
        </span>
      </div>
    : !authUser && content && content.length > 0 ? <span className="content-img"><img src={content} alt={item} /></span>
    : <span className="content-loading" />
  );
};

export default ContentImg;