import React, { useContext, useEffect, useState } from 'react';
import { db } from '../../firebase';
import ReactBody from 'react-body';
import * as routes from "constants/routes";
import { AuthContext } from '../../contexts/AuthContext';

const AdminSignUp = (props) => {
  const LOADING = 'loading';
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [signUpStatus, setSignUpStatus] = useState('');
  const [invites, setInvites] = useState({});
  const [error, setError] = useState(null);

  const { history, match } = props;

  const { onSignUp } = useContext(AuthContext);

  useEffect(() => {
    const ID = match && match.params ? match.params.id : false;
    if (!ID) {
      history.push(routes.Home);
    } else {
      db.getInvites().then(res => {
        const invites = res.val();
        const idArray = Object.keys(invites);
        if (!idArray.includes(ID)) {
          history.push(routes.Home);
        } else {
          const emailAddress = invites[ID].email ? invites[ID].email : '';
          setInvites(invites);
          setEmail(emailAddress);
        }
      });
    }
  }, [history, match]);

  useEffect(() => {
    if (signUpStatus === LOADING) {
      const goToAdmin = () => {
        history.push(routes.Admin)
      }

      const emails = Object.keys(invites).map(key => invites[key].email);
      const ID = match.params.id;

      if (emails.includes(email)) {
        setError(null);
        onSignUp({email, password}, ID, setError, goToAdmin);
      } else {
        setError({ message: "This email has not been invited to join NTS" });
        setSignUpStatus('');
      }
    }
  }, [signUpStatus, match, history]);

  const signUp = (evt) => {
    // TODO: Remove users
    // TODO: Move signup into a function
    // TODO: return feedback that invite has been sent - done
    // TODO: handle errors - done
    // TODO: move send email function to watch for db change on invites table - done
    evt.preventDefault();

    setSignUpStatus(LOADING);
  }

  const isInvalid = email === '' || password === '' || password2 !== password;

  return (
    <section id="admin" onLoad={ window.scrollTo(0,0) }>
      <div className="content-intro">
        <div className="content-wrap">
          <div className="admin-container-wrap">
            <h2>Admin Sign Up</h2>
            { email && email !== '' ?
              <form className="admin-form" onSubmit={signUp}>
                <input type="text" value={email} placeholder="* Email" disabled
                  onChange={event => setEmail(event.target.value)} />
                <input type="password" value={password} placeholder="* Password"
                  onChange={event => setPassword(event.target.value)} />
                <input type="password" value={password2} placeholder="* Re-enter Password"
                  onChange={event => setPassword2(event.target.value)} />
                <button id="submit" className="btn btn-quaternary" type="submit" disabled={isInvalid || signUpStatus !== ''}>
                  { signUpStatus === LOADING ?
                    <span className="btn-loading" />
                    : <span>sign up</span>
                  }
                </button>
                { error && <p className="error">{error.message}</p> }
              </form>
              : <div className="content-loading" />
            }
          </div>
        </div>
      </div>
      <ReactBody className="contact" />
    </section>
  );
}

export default AdminSignUp;