import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import * as routes from 'constants/routes';
import { AuthContext } from '../../contexts/AuthContext';

const Footer = (props) => {

  const { authUser, signout } = useContext(AuthContext);

  return (
    <footer>
      <div className="content-wrap">
        <div className="footer-top">
          <div className="logo">
            <Link to={routes.Home}><img src={require('../../images/cadd-logo-white.svg')} alt="CADD Microsystems Community" /></Link>
          </div>
          <div className="footer-nav">
            <nav className="nav-links">
              <Link to={routes.Training} className="nav-item nav-item-training">Training</Link>
              <Link to={routes.Extensions} className="nav-item nav-item-extensions">Extensions</Link>
              <Link to={routes.ContentPacks} className="nav-item nav-item-content-packs">Content Packs</Link>
              { authUser ? <Link to={routes.Admin}>Admin</Link> : null }
              { authUser ? <span className="link" onClick={signout}>Logout</span> : null }
              <div className="nav-item-user-group">
                <a href={routes.Login} className="nav-item nav-item-login">Login</a>
                <a href={routes.Signup} className="nav-item nav-item-signup btn btn-accent">Join Now</a>
              </div>
            </nav>
            <div className="nav-social">
              <a href="https://www.facebook.com/CADDMicrosystems" target="_blank" rel="noopener noreferrer"><i className="icon icon-facebook" /></a>
              <a href="https://twitter.com/cadd" target="_blank" rel="noopener noreferrer"><i className="icon icon-twitter" /></a>
              <a href="https://www.linkedin.com/company/cadd-microsystems/" target="_blank" rel="noopener noreferrer"><i className="icon icon-linkedin" /></a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="partners">
            <img src={require('../../images/partner-autodesk.png')} alt="Autodesk Platinum Partner" />
            <img src={require('../../images/partner-bluebeam.png')} alt="Bluebeam Platinum Partner" />
          </div>
          <p>GENERAL INQUIRIES<br />
          800.526.CADD (2233)<br />
          <a href="mailto:info@caddmicrosystems.com">info@caddmicrosystems.com</a></p>
        </div>
      </div>
    </footer>
  );
}

export default withRouter(Footer);