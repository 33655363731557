import React from 'react';

export const Logo = ({ color }) =>
  <svg width="391" height="129" viewBox="0 0 391 129" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
    <path d="M109.044 104.582C108.511 103.426 107.642 102.456 106.551 101.799C105.46 101.142 104.197 100.827 102.925 100.896C97.9299 100.896 95.0729 105.282 95.0729 111.474C95.0729 117.666 97.9668 122.052 102.98 122.052C105.985 122.052 107.754 120.781 109.505 117.814L115.146 121.149C114.091 123.507 112.346 125.489 110.141 126.835C107.936 128.18 105.375 128.826 102.796 128.687C94.0592 128.687 88.1978 122.015 88.1978 111.474C88.1978 101.32 94.2988 94.262 102.943 94.262C105.484 94.116 108.009 94.7421 110.187 96.0577C112.364 97.3734 114.093 99.3171 115.146 101.633L109.044 104.582Z" style={{ fill: color === 'white' ? '#FFFFFF' : '#193F6B' }}/>
    <path d="M134.794 128.76C126.057 128.76 120.048 122.365 120.048 111.603C120.048 100.84 126.094 94.335 134.794 94.335C143.494 94.335 149.429 100.73 149.429 111.51C149.429 122.291 143.457 128.76 134.794 128.76ZM134.794 100.969C130.112 100.969 127.016 104.655 127.016 111.51C127.016 118.366 130.26 122.125 134.941 122.125C139.623 122.125 142.701 118.44 142.701 111.603C142.701 104.766 139.402 100.969 134.739 100.969H134.794Z" style={{ fill: color === 'white' ? '#FFFFFF' : '#193F6B' }}/>
    <path d="M181.999 128.244V115.989C181.999 114.146 182.091 109.926 182.091 108.618C181.612 109.871 180.616 112.304 179.75 114.146L172.985 128.889L166.202 114.146C165.355 112.304 164.359 109.871 163.88 108.618C163.88 109.908 163.88 114.146 163.88 115.989V128.244H157.429V94.8701H163.88L170.829 109.982C171.695 111.824 172.672 114.368 173.114 115.51C173.557 114.275 174.497 111.824 175.363 109.982L182.091 94.8701H188.671V128.244H181.999Z" style={{ fill: color === 'white' ? '#FFFFFF' : '#193F6B' }}/>
    <path d="M223.029 128.244V115.989C223.029 114.146 223.139 109.926 223.139 108.618C222.66 109.871 221.646 112.304 220.798 114.146L214.015 128.889L207.251 114.146C206.384 112.304 205.407 109.871 204.91 108.618C204.91 109.908 204.91 114.146 204.91 115.989V128.244H198.385V94.8701H204.818L211.785 109.982C212.651 111.824 213.628 114.368 214.07 115.51C214.494 114.275 215.453 111.824 216.319 109.982L223.047 94.8701H229.609V128.244H223.029Z" style={{ fill: color === 'white' ? '#FFFFFF' : '#193F6B' }}/>
    <path d="M252.704 128.76C244.649 128.76 239.212 123.232 239.212 113.594V94.8701H245.884V113.501C245.884 118.846 248.465 122.237 252.704 122.237C256.944 122.237 259.579 118.938 259.579 113.594V94.8701H266.252V113.501C266.252 123.176 261.183 128.76 252.704 128.76Z" style={{ fill: color === 'white' ? '#FFFFFF' : '#193F6B' }}/>
    <path d="M296.849 128.244L284.61 110.018C283.799 108.784 282.767 107.125 282.269 106.333C282.269 107.567 282.362 111.861 282.362 113.704V128.447H275.781V94.8701H282.177L293.992 112.506C294.803 113.741 295.835 115.418 296.333 116.192C296.333 114.957 296.241 110.663 296.241 108.821V94.8701H302.821V128.244H296.849Z" style={{ fill: color === 'white' ? '#FFFFFF' : '#193F6B' }}/>
    <path d="M313.069 128.244V94.8701H319.742V128.244H313.069Z" style={{ fill: color === 'white' ? '#FFFFFF' : '#193F6B' }}/>
    <path d="M344.109 101.449V128.244H337.437V101.449H327.852V94.8701H353.657V101.449H344.109Z" style={{ fill: color === 'white' ? '#FFFFFF' : '#193F6B' }}/>
    <path d="M377.397 115.031V128.244H370.725V115.178L358.081 94.907H365.767L370.485 102.776C371.665 104.785 373.49 108.065 374.172 109.318C374.798 108.065 376.568 104.877 377.858 102.776L382.577 94.8701H390.152L377.397 115.031Z" style={{ fill: color === 'white' ? '#FFFFFF' : '#193F6B' }}/>
    <path d="M24.5331 28.0472C30.5236 28.0472 32.9935 31.9909 35.5924 36.3585L46.9097 28.7291C44.2371 22.1132 36.6615 15.1104 24.5331 15.1104C9.80588 15.1104 0 25.7989 0 37.8512V58.1226C0 70.2486 9.80588 80.8819 24.5331 80.8819C36.6615 80.8819 44.2924 73.9159 46.8913 67.2448L35.574 59.5232C32.9935 63.8908 30.5236 67.8345 24.5147 67.8345C23.2106 67.8693 21.9129 67.6406 20.6992 67.1621C19.4856 66.6836 18.3811 65.9652 17.4517 65.0498C16.5224 64.1344 15.7874 63.0409 15.2907 61.8348C14.7941 60.6287 14.546 59.3347 14.5614 58.0305V37.8512C14.5459 36.5454 14.7946 35.2499 15.2924 34.0425C15.7901 32.8351 16.5268 31.7408 17.4581 30.8251C18.3894 29.9095 19.4962 29.1914 20.712 28.7141C21.9278 28.2367 23.2275 28.0099 24.5331 28.0472Z" style={{ fill: color === 'white' ? '#FFFFFF' : '#193F6B' }}/>
    <path d="M67.8669 59.8734H87.7736V79.4261H102.353V37.7222C102.353 25.7989 92.5291 15.1104 77.8202 15.1104C63.1114 15.1104 53.2871 25.7989 53.2871 37.7222V79.4261H67.8669V59.8734ZM67.8669 37.7591C67.8669 35.1198 68.9156 32.5886 70.7822 30.7223C72.6488 28.8561 75.1805 27.8076 77.8202 27.8076C80.46 27.8076 82.9917 28.8561 84.8583 30.7223C86.7249 32.5886 87.7736 35.1198 87.7736 37.7591V47.5078H67.8669V37.7591Z" style={{ fill: color === 'white' ? '#FFFFFF' : '#193F6B' }}/>
    <path d="M162.203 37.7978C162.203 25.6718 152.36 16.3838 137.632 16.3838H112.989V79.4279H137.559C152.304 79.4279 162.129 70.2136 162.129 57.9955L162.203 37.7978ZM147.457 57.9955C147.457 62.7685 143.918 67.2098 137.503 67.2098H127.587V28.6572H137.559C143.973 28.6572 147.512 33.0248 147.512 37.8715L147.457 57.9955Z" style={{ fill: color === 'white' ? '#FFFFFF' : '#193F6B' }}/>
    <path d="M189.5 67.1348H179.547V67.1532H189.5V67.1348Z" style={{ fill: color === 'white' ? '#FFFFFF' : '#193F6B' }}/>
    <path d="M189.851 67.1346C196.026 66.9503 199.454 62.6749 199.454 57.9203V37.6489C199.454 32.8759 195.897 28.4346 189.482 28.4346H179.529V67.1346H189.851Z" style={{ fill: color === 'white' ? '#FFFFFF' : '#193F6B' }}/>
    <path d="M179.547 0.0368571V0L164.931 14.7797H179.547V0.0368571Z" style={{ fill: color === 'white' ? '#FFFFFF' : '#193F6B' }}/>
    <path d="M213.315 67.1348C209.628 73.8243 201.813 78.5236 192.357 79.2976C191.417 79.2976 190.514 79.4266 189.5 79.4266H221.333L233.48 67.1348H213.315Z" style={{ fill: color === 'white' ? '#FFFFFF' : '#193F6B' }}/>
    <path d="M208.928 0H179.547V14.7429H191.122C205.868 14.7429 215.692 23.9571 215.692 36.1569V57.9947C215.709 61.2199 214.89 64.3945 213.314 67.209H233.479V21.4509C233.479 9.30643 223.655 0 208.928 0Z" fill="#E98623"/>
    </g>
    <defs>
    <clipPath id="clip0">
    <rect width="390.023" height="129" fill="white"/>
    </clipPath>
    </defs>
  </svg>

export default Logo;