import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import * as routes from 'constants/routes';
import { ContentContext } from '../../contexts/ContentContext';
import Content from '../content/Content';
import ContentImg from '../content/ContentImg';
import ContentAdd from '../content/ContentAdd';
import ContactForm from './ContactForm';
import ReactBody from 'react-body';
import { Helmet } from 'react-helmet-async';
import Lottie from 'lottie-react';
import lottieContentPacks from '../../data/lottie-content-packs-2.json';

const Extensions = (props) => {
  
  const { siteContent } = useContext(ContentContext);

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  return (siteContent.extensions ?
    <section id="extensions">
      <Helmet>
        <title>CADD Community - Extensions</title>
      </Helmet>
      
      <div className="hero hero-color hero-primary">
        <div className="content-wrap">
          <div className="hero-content">
            <Content
              page="extensions"
              item="header"
              type="content" />
            <ContactForm />
          </div>
          <div className="hero-lottie">
            <Lottie animationData={lottieContentPacks} />
          </div>
        </div>
      </div>

      <div className="sub-hero sub-hero-tertiary-primary">
        <iframe title="vimeo-player" src="https://player.vimeo.com/video/354219351?h=314c6ab496" width="720" height="405" frameborder="0" allowfullscreen></iframe>
        <Content
          page="extensions"
          item="toolbox"
          type="content" />
      </div>

      <div className="extensions-posts content-posts content-screenshots">

        <div className="content-main video" id={`content-0`} key={0}>
          <div className="content-video">
            <iframe title="vimeo-player" src="https://player.vimeo.com/video/256804241?h=6ff721eccb" width="640" height="360" frameborder="0" allowfullscreen></iframe>
          </div>
          <Content
            page="extensions"
            item="posts"
            itemIndex={0}
            type="content" />
        </div>

        <div className="content-main video" id={`content-1`} key={1}>
          <div className="content-video">
            <iframe title="vimeo-player" src="https://player.vimeo.com/video/256807680?h=f25611d887" width="640" height="360" frameborder="0" allowfullscreen></iframe>
          </div>
          <Content
            page="extensions"
            item="posts"
            itemIndex={1}
            type="content" />
        </div>

        <div className="content-main video" id={`content-2`} key={2}>
          <ContentImg
            page="extensions"
            item="posts"
            itemIndex={2}
            type="img" />
          <Content
            page="extensions"
            item="posts"
            itemIndex={2}
            type="content" />
        </div>

        <div className="content-main video" id={`content-3`} key={3}>
          <ContentImg
            page="extensions"
            item="posts"
            itemIndex={3}
            type="img" />
          <Content
            page="extensions"
            item="posts"
            itemIndex={3}
            type="content" />
        </div> 

        {/*{ siteContent.extensions.posts ? siteContent.extensions.posts.map((post, index) => 
          <div className="content-main" id={`content-${index}`} key={index}>
            <ContentImg
              page="extensions"
              item="posts"
              itemIndex={index}
              type="img" />
            <Content
              page="extensions"
              item="posts"
              itemIndex={index}
              type="content" />
          </div> 
        ) : null }*/}

        {/*<ContentAdd
          page="extensions"
          item="posts"
          type="content" />*/}

      </div>

      <ReactBody className="extensions header-primary" />
    </section>
  : <div className="content-intro">
      <div className="content-loading page-loading" />
      <ReactBody className="extensions header-primary" />
    </div>
  );
};

export default Extensions;