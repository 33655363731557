import React, { Component } from 'react';
import * as routes from 'constants/routes';

class Index extends Component {

  constructor(props) {
    super(props);
    this.state = ({ redirect: false });
  }

  componentDidMount() {
    const { history } = this.props;
    history.push(routes.Home);
  }

  render() {
    return (
      <div id="index" className="content-outer">
        <div className="loading no-header" />
      </div>
    );
  }
}

export default Index;