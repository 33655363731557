import React, { useEffect, useState } from 'react';
import { auth } from '../../firebase';

const PasswordForgetForm = (props) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [emailSent, setEmailSent] = useState(false);
  const [emailSending, setEmailSending] = useState(false);

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  useEffect(() => {
    if (emailSending) {
      auth.doPasswordReset(email)
        .then(() => {
          setError(null);
          setEmailSent(true);
          setEmailSending(false);
        })
        .catch(error => {
          setError(error);
          setEmailSending(false);
        });
    }
  }, [emailSending]);

  const onSubmit = (event) => {
    event.preventDefault();

    setEmailSending(true);
  };

  const isInvalid = email === '';

  return (
    <div className="form-wrap">
      { emailSent ?
        <div className="auth-verify">
          <i className="icon icon-paper-plane" />
          <h3>Please check your email.</h3>
          <p>An email with a link to reset your password was sent to: {email}.</p>
        </div>
        : <form id="form-pass" className="admin-form" onSubmit={onSubmit}>
            <input
              autoFocus
              value={email}
              onChange={event => setEmail(event.target.value)}
              type="text"
              placeholder="Email Address"
            />
            <button className="btn btn-quaternary" disabled={isInvalid || emailSending} type="submit">
              { !emailSending ?
                <span>reset my password</span>
                : <span className="btn-loading" /> 
              }
            </button>
            { error && <p className="error">{error.message}</p> }
          </form>
      }
    </div>
  );
}

export default PasswordForgetForm;