import React, { useContext, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import renderHTML from 'react-render-html';
import { db, firebase } from '../../firebase';
import { Confirm, Menu, Dropdown } from 'semantic-ui-react';
import WithAuthorization from 'components/withAuthorization';
import pretty from 'pretty';
import arrayMove from 'array-move';
import { modulesHeader, modulesContent, modulesContentSlim, formatsHeader, formatsContent, formatsContentSlim } from '../functions';
import { ContentContext } from '../../contexts/ContentContext';

const SAVING = 'saving';
const DELETING = 'deleting';
const POSTS = 'posts';
const UP = 'up';
const DOWN = 'down';
const TOP = 'top';
const BOTTOM = 'bottom';
const USER = 'user';

const ContentEditor = (props) => {

  const { page, item, itemIndex, type } = props;

  const [editing, setEditing] = useState(false);
  const [contentEditStatus, setContentEditStatus] = useState('');
  const [content, setContent] = useState([]);
  const [contentID, setContentID] = useState('');
  const [postDeleteConfirmOpen, setPostDeleteConfirmOpen] = useState(false);

  const { siteContent, setSiteContent } = useContext(ContentContext);

  useEffect(() => {
    if (siteContent && siteContent[page] && siteContent[page][item] && siteContent[page] !== content[page]) {
      if (itemIndex >= 0) {
        setContent(siteContent[page][item][itemIndex][type]);
        setContentID(siteContent[page][item][itemIndex].id);
      } else {
        setContent(siteContent[page][item][type]);
      }
    }
  }, [siteContent]);

  useEffect(() => {
    if (contentEditStatus === SAVING) {
      db.getContent().then((siteContent) => {
        const contentUpdate = siteContent.val();
        if (contentID) {
          db.getPostIndex(page, item, contentID).then((res) => {
            const postIndex = Object.keys(res.val())[0];
            contentUpdate[page][item][postIndex][type] = content;
            updateDB(`${item}/${postIndex}`, content, contentUpdate);
          });
        } else if (!contentID && itemIndex) {
          contentUpdate[page][item][itemIndex][type] = content;
          updateDB(`${item}/${itemIndex}`, content, contentUpdate);
        } else {
          contentUpdate[page][item][type] = content;
          updateDB(item, content, contentUpdate);
        }
      });
    } else if (contentEditStatus === DELETING) {
      setTimeout(() => {
        db.getContent().then((siteContent) => {   
          const contentUpdate = siteContent.val();     
          db.getPostIndex(page, POSTS, contentID).then((res) => {       
            const postIndex = Object.keys(res.val())[0];
            const imageRef = contentUpdate[page].posts[postIndex].img;
            contentUpdate[page].posts.splice(postIndex, 1);
            db.savePosts(page, contentUpdate[page].posts).then(() => {
              setSiteContent(contentUpdate);
              if (imageRef && firebase.storage.refFromURL(imageRef)) {
                firebase.storage.refFromURL(imageRef).delete();
              }
              setContentEditStatus('');
            });
          });
        });
      }, 500);
    }
  }, [contentEditStatus]);

  const editOn = () => {
    if (!editing) {
      setEditing(true);
    }
  };

  const editOff = () => {
    if (editing) {
      setEditing(false);
    }
  };

  const openDeletePostConfirm = () => {
    setPostDeleteConfirmOpen(true);
  };

  const cancelDeletePost = () => {
    setPostDeleteConfirmOpen(false);
  };

  const saveContent = () => {
    setContentEditStatus(SAVING);
  };

  const updateDB = (path, content, update) => {
    db.saveContent(page, path, {[type]: pretty(content)}).then(() => {
      setSiteContent(update);
      setEditing(false)
      setContentEditStatus('');
    });
  };

  const deletePost = () => {
    setContentEditStatus(DELETING);
    setPostDeleteConfirmOpen(false);
  };

  const movePost = (postID, dir) => {
    db.getContent().then((siteContent) => {
      const contentUpdate = siteContent.val();
      db.getPostIndex(page, POSTS, contentID).then((res) => {
        const postIndex = Object.keys(res.val())[0];
        if (dir === UP) {
          contentUpdate[page].posts = arrayMove(contentUpdate[page].posts, postIndex, postIndex - 1)
        } else if (dir === DOWN) {
          contentUpdate[page].posts = arrayMove(contentUpdate[page].posts, postIndex, postIndex + 1)
        } else if (dir === TOP) {
          contentUpdate[page].posts = arrayMove(contentUpdate[page].posts, postIndex, 0)
        } else if (dir === BOTTOM) {
          contentUpdate[page].posts = arrayMove(contentUpdate[page].posts, postIndex, contentUpdate[page].posts.length - 1)
        }
        db.savePosts(page, contentUpdate[page].posts).then(() => {
          setSiteContent(contentUpdate);
        });
      });
    });
  };

  const updateContent = (content, source) => {
    if (source === USER) {
      setContent(content);
    }
  };

  return (
    content && content.length > 0 ?
      <div className={`content-editor-wrap content-text ${contentEditStatus === DELETING ? DELETING : ''}`}>
        <span className={`content-editor ${editing ? 'active' : ''}`} onClick={editOn}>
          <span className="content-display">
            {renderHTML(content)}
          </span>
          <ReactQuill
            theme="snow"
            className="content-edit"
            value={content}
            modules={item === 'header' ? modulesHeader : item === POSTS ?  modulesContent : item === 'numbers' ? modulesHeader : modulesContentSlim}
            // formats={item === 'header' ? formatsHeader : item === POSTS ? formatsContent : item === 'numbers' ? modulesHeader : formatsContentSlim}
            onChange={(content, delta, source, editor) => updateContent(content, source)} 
          />
          <button className="btn btn-success content-save" onClick={saveContent} disabled={contentEditStatus !== ''}>
            { contentEditStatus === SAVING ?
              <span className="btn-loading" />
              : 'save'
            }
          </button>
          <button className="btn btn-white content-cancel" onClick={() => setEditing(false)}>
            <i className="icon icon-cross" />
          </button>
        </span>
        { item.indexOf('post') !== -1 ?
          <span className="content-post-nav">
            <Menu className="dark">
              <Dropdown trigger={
                <div className="trigger">
                  <i className="icon icon-ellipsis" />
                </div>
              }>
                <Dropdown.Menu>
                  { itemIndex > 0 ?
                    <span className="link menu-item" onClick={() => movePost(itemIndex, TOP)}><i className="icon icon-chevrons-up" /> move top</span>
                    : null
                  }
                  { itemIndex > 0 ?
                    <span className="link menu-item" onClick={() => movePost(itemIndex, UP)}><i className="icon icon-arrow-up" /> move up</span>
                    : null
                  }
                  { itemIndex < siteContent[page].posts.length - 1 ?
                    <span className="link menu-item" onClick={() => movePost(itemIndex, DOWN)}><i className="icon icon-arrow-down" /> move down</span>
                    : null
                  }
                  { itemIndex < siteContent[page].posts.length - 1 ?
                    <span className="link menu-item" onClick={() => movePost(itemIndex, BOTTOM)}><i className="icon icon-chevrons-down" /> move bottom</span>
                    : null
                  }
                  <span className="link menu-item" onClick={() => openDeletePostConfirm(itemIndex)}><i className="icon icon-trash" /> delete</span>
                </Dropdown.Menu>
              </Dropdown>
            </Menu>
            <Confirm
              className='confirm delete'
              open={postDeleteConfirmOpen}
              header='Sure you want to delete this job post?'
              content="The post will be permanently removed."
              cancelButton='nevermind'
              confirmButton='yes, delete'
              onCancel={cancelDeletePost}
              onConfirm={deletePost} 
            />
          </span>
          : null
        }
        <span className="content-loading" />
      </div>
    : null
  )
}

export default WithAuthorization(ContentEditor);
