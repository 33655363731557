import { db } from './firebase';

// User Functions

export const createUser = (id, email, lowercase_email, role) =>
  db.ref(`users/${id}`).set({
    email,
    lowercase_email,
    role
  });

export const getUser = (userID) =>
  db.ref(`users/${userID}`).once('value');

export const getUsers = () =>
  db.ref('users/').once('value');

export const getInvites = () =>
  db.ref('invites/').once('value');

export const lookupUserByEmail = (email) =>
  db.ref('users/').orderByChild('lowercase_email').equalTo(email).once('value');

// Content Functions

export const getContent = () =>
  db.ref('content').once('value');

export const saveContent = (page, item, content) =>
  db.ref(`content/${page}/${item}`).update(content);

export const getPostIndex = (page, item, id) =>
  db.ref(`content/${page}/${item}`).orderByChild('id').equalTo(id).once('value');

export const savePosts = (page, posts) =>
  db.ref(`content/${page}/posts`).set(posts);

export const getSettings = () =>
  db.ref('settings/').once('value')

export const updateEmailSettings = (careers, contact) =>
  db.ref('settings').set({ emailCareers: careers, emailContact: contact });

export const inviteNewUser = (id, email, callback) => {
  const ref = db.ref(`invites/${id}`);
  ref.set({
    email,
  }).then(() => {
    callback();
  })
};

export const removeNewUserFromItemInviteTable = (userKey) =>
  db.ref(`invites/${userKey}`).remove();

export const deleteUser = (userKey) =>
  db.ref(`users/${userKey}`).remove();


export const saveFiles = (files, type) =>
  db.ref(`content/${type}/files`).set(files);
