import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { db, firebase } from '../../firebase';
import { Modal } from 'semantic-ui-react';
import WithAuthorization from 'components/withAuthorization';
import ContentAddForm from './ContentAddForm';
import pretty from 'pretty';
import shortid from 'shortid';
import Dropzone from 'react-dropzone';
import { convertFileSize, modulesHeader, modulesContent, formatsHeader, formatsContent } from '../functions';
import { AuthContext } from '../../contexts/AuthContext';
import { ContentContext } from '../../contexts/ContentContext';

const HEADER = 'header';
const USER = 'user';
const POSTING = 'posting';
const POSTED = 'posted';

const ContentAdd = (props) => {
  const [state, setState] = useState({
    ...INITIAL_STATE
  });

  const { authUser } = useContext(AuthContext);
  const { siteContent, setSiteContent } = useContext(ContentContext);

  const quillHeaderRef = useRef(null);
  const quillContentRef = useRef(null);

  useEffect(() => {
    // returned function will be called on component unmount
    return () => {
      state.contentAddImgUpload.forEach((upload) => {
        upload.cancel();
      });
    }
  }, [])

  useEffect(() => {
    if (state.contentAddModalOpen) {
      if (props.type === HEADER) {
        quillHeaderRef.current.focus();
      } else if (props.type !== 'image') {
        quillContentRef.current.focus();
      }
    }
  }, [state.contentAddModalOpen])

  useEffect(() => {
    const { contentAddStatus, contentAddImg, contentAddImgUpload, contentAddID } = state;
    const { page, item, image } = props;

    if (contentAddStatus === POSTING) {
      // This getContent lookup is not necessary but...
      // Just trying to make sure that all the other content is up-to-date so things don't get overwritten if two people are editing
      db.getContent().then((siteContent) => {
        const contentUpdate = siteContent.val();
        const contentIndex = siteContent.val()[page][item] ? siteContent.val()[page][item].length : 0;

        if (image !== 'false') {
          let storageRef = firebase.storage.ref('content');
          const uploadTask = storageRef.child(`${page}/${item}/${contentAddID}/${contentAddImg[0].name}`).put(contentAddImg[0]);
          contentAddImgUpload.push(uploadTask);
          uploadTask.on('state_changed', (snapshot) => {
            if (snapshot.bytesTransferred === snapshot.totalBytes) {
              console.log('Save Complete');
            }
          }, (error) => {
            console.log('Upload error: ', error);
            setState(prevState => ({
              ...prevState,
              error: error
            }));
          }, () => {
            if (!contentUpdate[page][item]) {
              contentUpdate[page][item] = [];
            }
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              saveContentDone(contentIndex, contentUpdate, downloadURL);
            });
          });
        } else {
          saveContentDone(contentIndex, contentUpdate);
        }
      });
    } else if (contentAddStatus === POSTED) {
      setTimeout(() => {
        setState(prevState => ({
          ...prevState,
          ...INITIAL_STATE
        }));
        closeContentAddModal();
      }, 1000);
    }
  }, [state.contentAddStatus])

  const openContentAddModal = (event) => {
    event.preventDefault();
    setState(prevState => ({
      ...prevState,
      contentAddModalOpen: true,
      contentAddID: shortid.generate()
    }));
  };

  const closeContentAddModal = () => {
    setState(prevState => ({
      ...prevState,
      ...INITIAL_STATE
    }));
  };

  const updateQuill = (item, content, delta, source, editor) => {
    if (source === USER) {
      if (editor.getLength() > 1) {
        setState(prevState => ({
          ...prevState,
          [item]: content
        }));
      } else {
        setState(prevState => ({
          ...prevState,
          [item]: INITIAL_STATE[item]
        }));
      }
    }
  };

  const focusQuill = (item) => {
    document.getElementById(`quill-${item}`).classList.add('focus');
  };

  const blurQuill = (item) => {
    document.getElementById(`quill-${item}`).classList.remove('focus');
  };

  const setContentImgFile = (file) => {
    setState(prevState => ({
      ...prevState,
      contentAddImg: file
    }));
  };

  const removeContentImgFile = (event) => {
    event.preventDefault();
    setState(prevState => ({
      ...prevState,
      contentAddImg: []
    }));
  };

  const saveContentAdd = (event) => {
    event.preventDefault();

    setState(prevState => ({
      ...prevState,
      contentAddStatus: POSTING
    }));
  };

  const saveContentDone = (contentIndex, contentUpdate, downloadURL) => {
    const { contentAddHeader, contentAddContent, contentAddID } = state;
    const { page, item, type } = props;

    contentUpdate[page][item][contentIndex] = { 
      id: contentAddID, 
      header: type === HEADER ? contentAddHeader : [], 
      content: contentAddContent ? contentAddContent : [], 
      img: downloadURL ? downloadURL : []
    };
    db.saveContent(page, `${item}/${contentIndex}`, { id: contentAddID, header: type === HEADER ? pretty(contentAddHeader) : [], content: contentAddContent ? pretty(contentAddContent) : [], img: downloadURL ? downloadURL : [] }).then(() => {
      setSiteContent(contentUpdate);
      setTimeout(() => {
        setState(prevState => ({
          ...prevState,
          contentAddStatus: POSTED
        }));
      }, 750);
    });
  };

  const isInvalid = props.type === HEADER ? state.contentAddHeader === '' : null || (props.type !== 'image' && state.contentAddContent === '') || (!state.contentAddImg[0] && props.image !== 'false');

  return ( authUser ?
    <div className="content-new">

      <button className="btn btn-primary" onClick={openContentAddModal}>
        new section
      </button>

      <ContentAddForm
        type={props.type}
        image={props.image}
        contentAddStatus={state.contentAddStatus}
        contentAddHeader={state.contentAddHeader}
        contentAddContent={state.contentAddContent}
        contentAddImg={state.contentAddImg}
        contentAddModalOpen={state.contentAddModalOpen}
        closeContentAddModal={closeContentAddModal}
        saveContentAdd={saveContentAdd}
        focusQuill={focusQuill}
        blurQuill={blurQuill}
        updateQuill={updateQuill}
        setContentImgFile={setContentImgFile}
        removeContentImgFile={removeContentImgFile}
        isInvalid={isInvalid}
        quillHeaderRef={quillHeaderRef}
        quillContentRef={quillContentRef}
        INITIAL_STATE={INITIAL_STATE}
        HEADER={HEADER}
        error />

    </div>
    : null
  )
}

const INITIAL_STATE = {
  contentAddStatus: '',
  contentAddHeader: '<h2>\n</h2>',
  contentAddContent: '',
  contentAddImg: [],
  contentAddImgUpload: [],
  contentAddID: '',
  error: [],
  contentAddModalOpen: false
};

export default ContentAdd;
