import React, { useContext, useEffect, useState } from 'react';
import { db, firebase } from '../../firebase';
import { Confirm } from 'semantic-ui-react';
import { AuthContext } from '../../contexts/AuthContext';

const AdminUsers = (props) => {
  const [error, setError] = useState(null);
  const [users, setUsers] = useState({});
  const [showDeleteUserConfirm, setShowDeleteUserConfirm] = useState(false);
  const [userDeleteKey, setUserDeleteKey] = useState('');
  const [deleting, setDeleting] = useState(false);

  const { authUser } = useContext(AuthContext);

  useEffect(() => {
    if (authUser) {
      db.getUsers().then(res => {
        setUsers(res.val());
      });
    }
  }, [authUser]);

  useEffect(() => {
    if (deleting) {
      const uid = userDeleteKey;
      firebase.removeUserAuthentication({ uid })
      .then(() => {
        db.deleteUser(uid)
        .then(() => {
          db.getUsers().then(res => {
            setUsers(res.val());
            setShowDeleteUserConfirm(false);
            setUserDeleteKey('');
            setDeleting(false);
          });
        })
        .catch(err => {
          console.log("ERR", err);
          showDeleteError();
        });
      })
      .catch(err => {
        console.log("ERR", err);
        showDeleteError();
      });
    }
  }, [deleting]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 2000);
    }
  }, [error]);

  const toggleRemoveUserConfirm = (uid) => {
    setShowDeleteUserConfirm(!showDeleteUserConfirm);
    setUserDeleteKey(uid);
  };

  const removeAdmin = () => {
    setDeleting(true)
    setShowDeleteUserConfirm(false);
  };

  const showDeleteError = () => {
    setError({message: 'Could not delete user at this time'});
    setShowDeleteUserConfirm(false);
    setUserDeleteKey('');
    setDeleting(false);
  };

  const createCurrentAdmins = () => {
    return Object.keys(users).map(key => {
      const email = users[key].email;
      return (
        <div key={email} className="user-info admin-item">
          <span className="link" href={`mailto:${email}`} target="_blank">{email}</span>
          {key !== authUser.uid ?
            <div className="admin-remove item-remove" onClick={() => toggleRemoveUserConfirm(key)}>
              <i className="icon icon-trash" />
            </div>
            : <span className="user-you">(you)</span>
          }
        </div>
      )
    })
  };

  const currentAdmins = createCurrentAdmins();

  return (
    <div className='current-admins-wrap'>
      <h2>Current administrators</h2>
      <div className="user-info-list admin-item-list">
        {currentAdmins}
        { deleting ?
          <div className="admin-delete">
            <h3>Deleting user...</h3>
            <div className="inline-loading" />
          </div>
          : null
        }
      </div>
      { error && <p className="error">{error.message}</p> }
      <Confirm
        className='confirm delete'
        open={showDeleteUserConfirm}
        header='Sure you want to delete this user?'
        content="The user will be permanently removed."
        cancelButton='nevermind'
        confirmButton='yes, delete'
        onCancel={() => toggleRemoveUserConfirm('')}
        onConfirm={removeAdmin} 
      />
    </div>
  );
}

export default AdminUsers;