import React from 'react';
import { Link } from 'react-router-dom';
import ReactBody from 'react-body';
import PasswordChangeForm from './PasswordChangeForm';
import WithAuthorization from 'components/withAuthorization';

const PasswordChangePage = ({ history }) =>
  <section id="pass-change" className="content-outer" onLoad={ window.scrollTo(0,0) }>
    <h1>Change Password</h1>
    <PasswordChangeForm history={history} />
    <Link className="auth-bottom-link" to="/account">my account</Link>
    <ReactBody className="pass-change" />
  </section>

export default WithAuthorization(PasswordChangePage);