import { useEffect } from 'react';

export const convertFileSize = (bytes) => {
  let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

// eslint-disable-next-line
export const useMountEffect = (effectFn) => useEffect(effectFn, []);

export const modulesHeader = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, false] }],
    [{ 'align': [] }],
    ['bold', 'italic', 'underline'],
    ['link'],
    ['clean']
  ],
}

export const modulesContentSlim = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, false] }],
    [{ 'align': [] }],
    ['bold', 'italic', 'underline'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    ['link'],
    ['clean'],
    ['image']
  ],
}

export const modulesContent = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, false] }],
    [{ 'align': [] }],
    ['bold', 'italic', 'underline'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    ['link'],
    ['clean'],
    ['image'],
    ['video']
  ],
}

export const modulesBlog = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, false] }],
    [{ 'align': [] }],
    ['bold', 'italic', 'underline'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    ['link'],
    ['clean'],
    ['image'],
    ['video']
  ],
}

export const formatsContentSlim = [
  'header',
  'align',
  'bold', 'italic', 'underline',
  'list', 'bullet',
  'link',
  'clean',
  'image',
]

export const formatsContent = [
  'header',
  'align',
  'bold', 'italic', 'underline',
  'list', 'bullet',
  'link',
  'clean',
  'image',
  'video'
]

export const formatsHeader = [
  'header',
  'bold', 'italic', 'underline',
  'link'
]

export const findImage = (string) => {
  const myRegex = /<img[^>]+src="([^">]+)"/g;
  const src = myRegex.exec(string);
  return src ? src[1] : '';
}

export const toText = (node) => {
  let tag = document.createElement('div')
  tag.innerHTML = node
  node = tag.innerText
  return node
};

export const shortenText = (text, start, maxLength) => {
  return text.length > maxLength ? text.slice(start, maxLength) + '...' : text
};

export const spaceToDash = (string) => {
  return string.replace(/\s+/g, '-').toLowerCase();
};