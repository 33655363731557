import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const AdminLogin = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loggingIn, setLoggingIn] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  const handleErrorState = error => {
    setError(error);
  }

  const handleSuccess = () => {
    setLoggedIn(true);
  }
  
  const { onSubmit } = props;

  const isInvalid = email === '' || password === '';

  return (
    <form className="admin-form" onSubmit={(evt) => onSubmit(evt, {email, password}, handleSuccess, handleErrorState)}>
      <input autoFocus type="text" value={email} placeholder="* Email"
        onChange={event => setEmail(event.target.value)} />
      <input type="password" value={password} placeholder="* Password"
        onChange={event => setPassword(event.target.value)} />
      <button id="submit" className={`btn btn-primary ${loggedIn ? 'btn-success' : ''}`} type="submit" disabled={isInvalid}>
        { !loggingIn && !loggedIn ?
          <span>login</span>
          : !loggingIn && loggedIn ?
            <span><i className="icon icon-checkmark-circle" />logged in</span>
          : <span className="btn-loading" />
        }
      </button>
      <Link to="/pass-forget">forgot password?</Link>
      { error && <p className="error">{error.message}</p> }
    </form>
  );
}

export default AdminLogin;