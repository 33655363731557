export const Index = '/index';
export const Home = '/';
export const Training = '/training';
export const Extensions = '/extensions';
export const ContentPacks = '/content-packs';
export const Admin = '/admin';
export const AddUser = '/add-admin';
export const AdminSignUp = '/admin-signup';
export const PasswordForget = '/pass-forget';
export const PasswordChange = '/pass-change';
export const Login = "https://get.caddcommunity.com/#/login";
export const Signup = "http://pages.caddmicrosystems.com/LP-CADD-Community-Request.html";
export const Blog = "http://www.caddmicrosystems.com/blog";