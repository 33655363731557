import React, { useEffect } from 'react';

const PageNotFound = () => {
  
  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  return (
    <div className="content-intro">
      <div className="page-not-found">
        <h1>404 &mdash; Sorry, we couldn't find this page.</h1>
      </div>
    </div>
  )
}

export default PageNotFound;