import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as routes from 'constants/routes';
import { db } from '../../firebase';
import { ContentContext } from '../../contexts/ContentContext';
import { AuthContext } from '../../contexts/AuthContext';
import { Menu, Dropdown } from 'semantic-ui-react';
import Logo from './Logo';

const Header = (props) => {

  const location = useLocation();
  const [headerShow, setHeaderShow] = useState(false);
  const [headerHide, setHeaderHide] = useState(false);
  const [logoColor, setLogoColor] = useState('dark');
  const { siteContent, setSiteContent } = useContext(ContentContext);
  const { authUser, signout } = useContext(AuthContext);

  useEffect(() => {
    let oldScroll = 0;

    const handleScroll = (event) => {
      let pageY = window.scrollY;
      if (pageY > 500 && headerShow && oldScroll < pageY && !headerHide) {
        setHeaderHide(true);
      } else if (pageY > 500 && !headerShow && oldScroll > pageY && headerHide) {
        setHeaderHide(false);
      }
      if (pageY > 1000 && !headerShow && oldScroll > pageY) {
        setHeaderShow(true);
      } else if ((pageY < 500 && headerShow) || (oldScroll < pageY)) {
        setHeaderShow(false);
      }
      if (pageY === 0) {
        setHeaderShow(false);
        setHeaderHide(false);
      }
      oldScroll = pageY;
    }
    if (window.innerWidth > 768) {
      window.addEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(() => {
    const getContent = () => {
      db.getContent().then((content) => {
        setSiteContent(content.val());
      });
    };
    if (Object.keys(siteContent).length === 0) {
      getContent();
    }
  }, []);

  useEffect(() => {
    if (location.pathname.indexOf('training') !== -1 ||
      location.pathname.indexOf('extensions') !== -1 ||
      location.pathname.indexOf('content-packs') !== -1) {
      setLogoColor('white');
    } else {
      setLogoColor('dark');
    }
  }, [location])

  return (
    <header className={`header-wrap ${headerShow ? 'show' : ''} ${headerHide ? 'hide' : ''}`}>
      <div className="logo">
        <Link to="/">
          <Logo color={logoColor} />
        </Link>
      </div>
      { window.innerWidth < 1024 ?
        <Menu>
          <Dropdown trigger={
            <div className="trigger">
              <i className="icon icon-menu" />
            </div>
          }>
            <Dropdown.Menu>
              <div className="menu-close"><i className="icon icon-cross" /></div>
              <Link to={routes.Training} className="nav-item nav-item-training">Training</Link>
              <Link to={routes.Extensions} className="nav-item nav-item-extensions">Extensions</Link>
              <Link to={routes.ContentPacks} className="nav-item nav-item-content-packs">Content Packs</Link>
              <a href={routes.Blog} className="nav-item nav-item-contact" target="_blank" rel="noopener noreferrer">Blog</a>
              { authUser ? <Link to={routes.Admin}>Admin</Link> : null }
              { authUser ? <span className="link" onClick={signout}>Logout</span> : null }
              <div className="nav-item-user-group">
                <a href={routes.Login} className="nav-item nav-item-login">Login</a>
                <a href={routes.Signup} className="nav-item nav-item-signup btn btn-accent">Join Now</a>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </Menu>

        : <nav className="nav-header">
            <Link to={routes.Training} className="nav-item nav-item-training">Training</Link>
            <Link to={routes.Extensions} className="nav-item nav-item-extensions">Extensions</Link>
            <Link to={routes.ContentPacks} className="nav-item nav-item-content-packs">Content Packs</Link>
            <a href={routes.Blog} className="nav-item nav-item-contact" target="_blank" rel="noopener noreferrer">Blog</a>
            <div className="nav-item-user-group">
              <a href={routes.Login} className="nav-item nav-item-login">Login</a>
              <a href={routes.Signup} className="nav-item nav-item-signup btn btn-accent" target="_blank">Join Now</a>
            </div>
          </nav>
      }
    </header>
  );
}

export default Header;