import React from 'react';
import ReactQuill from 'react-quill';
import { Modal } from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import { convertFileSize, modulesHeader, modulesContent, formatsHeader, formatsContent } from '../functions';

const ContentAddForm = ({ type, image, contentAddStatus, contentAddHeader, contentAddContent, contentAddImg, contentAddModalOpen, closeContentAddModal, saveContentAdd, focusQuill, blurQuill, updateQuill, setContentImgFile, removeContentImgFile, isInvalid, quillHeaderRef, quillContentRef, error, INITIAL_STATE, HEADER }) =>
  <Modal
    open={contentAddModalOpen}
    onClose={closeContentAddModal}
    closeOnDimmerClick={false}
    className="modal-add-section">
    <span className="modal-close" onClick={closeContentAddModal}>
      <i className="icon icon-cross" />
    </span>
    <Modal.Header>
      <h1>Add New Section</h1>
    </Modal.Header>
    <Modal.Content>
      <div className="content-add">
        <form id="content-add-form" onSubmit={saveContentAdd}>
          { type === HEADER ?
            <div className={`form-row row-content content-header ${contentAddHeader && contentAddHeader.length > INITIAL_STATE.contentAddHeader.length ? 'complete' : ''}`}>
              <label className="required">Section Header</label>
              <ReactQuill  
                id="quill-content"
                ref={quillHeaderRef}
                value={contentAddHeader || ''}
                modules={modulesHeader}
                formats={formatsHeader}
                placeholder="Enter section content" 
                onFocus={() => focusQuill('content')}
                onBlur={() => blurQuill('content')}
                onChange={(content, delta, source, editor) => updateQuill('contentAddHeader', content, delta, source, editor)} 
              />
            </div>
            : null
          }
          { type !== 'image' ?
            <div className={`form-row row-content ${contentAddContent && contentAddContent.length > INITIAL_STATE.contentAddContent.length + 1 ? 'complete' : ''}`}>
              <label className="required">Section Content</label>
              <ReactQuill  
                id="quill-content"
                ref={quillContentRef}
                theme="snow" 
                value={contentAddContent || ''}
                modules={modulesContent}
                formats={formatsContent}
                placeholder="Enter section content" 
                onFocus={() => focusQuill('content')}
                onBlur={() => blurQuill('content')}
                onChange={(content, delta, source, editor) => updateQuill('contentAddContent', content, delta, source, editor)} 
              />
            </div>
            : null
          }
          { image !== 'false' ?
            <div className={`form-row row-img ${contentAddImg && contentAddImg[0] ? 'complete' : ''}`}>
              <label className="required">Section Image</label>
              { contentAddImg && contentAddImg[0] ?
                <span className="file-content file-img">
                  <img src={contentAddImg[0].preview} alt={contentAddImg[0].name} />
                  <p>{contentAddImg[0].name} &ndash; <span>{convertFileSize(contentAddImg[0].size)}</span></p>
                  <button className="btn btn-alert" onClick={removeContentImgFile}>remove file</button>
                </span>
                : <Dropzone 
                    style={{}}
                    multiple={false}
                    disabled={contentAddStatus !== ''}
                    className="content-add-img-dropzone files-dropzone"
                    activeClassName="active"
                    onDrop={(file) => setContentImgFile(file)}>
                    <div className="content-img-drop files-drop">
                      <h4>Drop the section image here or...</h4>
                      <div className="btn btn-primary">click to upload</div>
                      <span className="files-drop-msg">
                        <i className="icon icon-dropzone" />
                        <p>Drop file here</p>
                      </span>
                    </div>
                  </Dropzone>
              }
            </div>
            : null
          }

          <button className="btn btn-quaternary" type="submit" disabled={isInvalid || contentAddStatus !== ''}>
            { contentAddStatus === 'posting' ?
                <span className="btn-loading" />
              : contentAddStatus === 'posted' ?
                <span className="btn-complete">
                  <i className="icon icon-check" />
                  section added!
                </span>
              : 'add section'
            }
          </button>
          
          { error && <p className="error">{error.message}</p> }

        </form>
      </div>
    </Modal.Content>
  </Modal>

export default ContentAddForm;