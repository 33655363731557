import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import * as routes from 'constants/routes';
import { ContentContext } from '../../contexts/ContentContext';
import Content from '../content/Content';
import ContentImg from '../content/ContentImg';
import ContentAdd from '../content/ContentAdd';
import ReactBody from 'react-body';
import { Helmet } from 'react-helmet-async';
import Lottie from 'lottie-react';
import lottieHome from '../../data/lottie-home.json';

const Home = (props) => {

  const { siteContent } = useContext(ContentContext);

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  return (siteContent.home ?
    <section id="home">
      <Helmet>
        <title>CADD Community</title>
      </Helmet>
      <div className="hero">
        <div className="content-wrap">
          <div className="hero-content">
            <Content
              page="home"
              item="header"
              type="content" />
            <Link to={routes.Training} className="btn btn-primary-lighter">
              Learn More
            </Link>
          </div>
          <div className="hero-lottie">
            <Lottie animationData={lottieHome} />
          </div>
        </div>
      </div>

      <div className="home-tools">
        <div className="content-wrap">
          <div className="home-logos">
            { siteContent.home.logos ? siteContent.home.logos.map((post, index) => 
              <div className="home-logo" id={`content-${index}`} key={index}>
                <ContentImg
                  page="home"
                  item="logos"
                  itemIndex={index}
                  type="img" />
              </div> 
            ) : null }
          </div>
          <ContentAdd
            page="home"
            item="logos"
            type="image" />
          <div className="home-tools-content">
            <Content
              page="home"
              item="tools"
              type="content" />
          </div>
        </div>
      </div>

      <div className="home-numbers">
        <div className="content-wrap">
          <div className="home-number">
            <Content
              page="home"
              item="numbers"
              type="users" />
          </div>
          <div className="home-number">
            <Content
              page="home"
              item="numbers"
              type="extensions" />
          </div>
          <div className="home-number">
            <Content
              page="home"
              item="numbers"
              type="courses" />
          </div>
          <div className="home-number">
            <Content
              page="home"
              item="numbers"
              type="modeling" />
          </div>
        </div>
      </div>

      <div className="home-posts content-posts">

        { siteContent.home.posts ? siteContent.home.posts.map((post, index) => 
          <div className="content-main" id={`content-${index}`} key={index}>
            <ContentImg
              page="home"
              item="posts"
              itemIndex={index}
              type="img" />
            <Content
              page="home"
              item="posts"
              itemIndex={index}
              type="content" />
          </div> 
        ) : null }

        <ContentAdd
          page="home"
          item="posts"
          type="content" />

      </div>

      <ReactBody className="home" />
    </section>
  : <div className="content-intro">
      <div className="content-loading page-loading" />
      <ReactBody className="home" />
    </div>
  );
};

export default Home;