import React, { useState, createContext } from "react";

export const ContentContext = createContext();

export function ContentProvider({ children }) {
  const [siteContent, setSiteContent] = useState({});

  const doSetSiteContent = (updatedContent) => {
    setSiteContent(updatedContent);
  };

  const value = {
    siteContent,
    setSiteContent: doSetSiteContent,
  };

  return (
    <ContentContext.Provider value={value}>{children}</ContentContext.Provider>
  );
}
