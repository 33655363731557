import React from 'react';
import ReactBody from 'react-body';
import PasswordForgetForm from './PasswordForgetForm';

const PasswordForgetPage = ({ history }) =>
  <section id="admin">
    <div className="content-intro">
      <div className="content-wrap">
        <div className="admin-container-wrap">
          <h2>Password Reset</h2>
          <PasswordForgetForm history={history} />
          <span className="link" onClick={() => { history.goBack() }}>go back</span>
        </div>
      </div>
    </div>
    <ReactBody className="admin" />
  </section>

export default PasswordForgetPage;
