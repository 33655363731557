import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Header from "components/core/Header";
import Footer from "components/core/Footer";
import Index from "components/core/Index";
import Home from "components/core/Home";
import Training from "components/core/Training";
import Extensions from "components/core/Extensions";
import ContentPacks from "components/core/ContentPacks";
import Admin from "components/admin/Admin";
import AdminSignUp from "components/admin/AdminSignUp";
import PasswordForget from "components/admin/PasswordForget";
import PasswordChange from "components/admin/PasswordChange";
import PageNotFound from "components/core/PageNotFound";
import * as routes from "constants/routes";
import { AuthProvider } from "../contexts/AuthContext";
import { ContentProvider } from "../contexts/ContentContext";

const App = () => (
  <BrowserRouter>
    <AuthProvider>
      <ContentProvider>
        <div className="app-wrap">
          <Header />
          <Switch>
            <Route exact path={routes.Index} component={Index} />
            <Route exact path={routes.Home} component={Home} />
            <Route exact path={routes.Training} component={Training} />
            <Route exact path={routes.Extensions} component={Extensions} />
            <Route exact path={routes.ContentPacks} component={ContentPacks} />
            <Route exact path={routes.Admin} component={Admin} />
            <Route path={`${routes.AdminSignUp}/:id`} component={AdminSignUp} />
            <Route
              exact
              path={routes.PasswordForget}
              component={PasswordForget}
            />
            <Route
              exact
              path={routes.PasswordChange}
              component={PasswordChange}
            />
            <Route exact path="/404" component={PageNotFound} />
            <Route path="*" component={PageNotFound} />
          </Switch>
          <Footer />
        </div>
      </ContentProvider>
    </AuthProvider>
  </BrowserRouter>
);

export default App;
