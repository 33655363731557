import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';

const ContactForm = (props) => {

  const [contactModalOpen, setContactModalOpen] = useState(false);

  return (

    <div className="contact">

      <button className="btn btn-accent" onClick={() => {
        window.open('https://pages.caddmicrosystems.com/LP-CADD-Community-Request.html', '_blank');
        // setContactModalOpen(true)}
      }}>
        Learn More
      </button>

      <Modal
        open={contactModalOpen}
        onClose={() => setContactModalOpen(false)}
        closeOnDimmerClick={false}
        className="modal-contact">
        <span className="modal-close" onClick={() => setContactModalOpen(false)}>
          <i className="icon icon-cross" />
        </span>
        <Modal.Header>
          <h1>Get in touch</h1>
        </Modal.Header>
        <Modal.Content>
          <div className="contact-form">
            
            <form>

              <div className="form-row required">
                <label>Name</label>
                <input type="text" placeholder="Please tell us your name" />
              </div>

              <div className="form-row">
                <label>Company</label>
                <input type="text" placeholder="Please tell us your company name" />
              </div>

              <div className="form-row required">
                <label>Email</label>
                <input type="text" placeholder="Please tell us your email" />
              </div>

              <div className="form-row">
                <label>Phone</label>
                <input type="text" placeholder="Please tell us your phone number" />
              </div>

              <div className="form-row required">
                <label>Zip</label>
                <input type="text" placeholder="Please tell us your zip code" />
              </div>

              <div className="form-row required">
                <label>Do you work for a government agency, public school, or systems intregrator</label>
                <input type="text" placeholder="Please tell us your phone number" />
              </div>

              <div className="form-row">
                <label>Comments</label>
                <textarea rows="4" type="text" placeholder="Anything else to tell us?" />
              </div>

              <button className="btn btn-accent">Submit</button>

            </form>

          </div>
        </Modal.Content>
      </Modal>

    </div>
  );
};

export default ContactForm;