import React from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import App from "./components/App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./styles/App.css";
import ReactGA from "react-ga4";

ReactGA.initialize("G-20ZBGM863J");

ReactDOM.render(
  <HelmetProvider>
    <App />
  </HelmetProvider>,
  document.getElementById("root")
);
serviceWorkerRegistration.register();
