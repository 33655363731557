import React, { useState, useEffect, createContext } from 'react';
import { auth, db, firebase } from '../firebase';

export const AuthContext = createContext();

export function AuthProvider({ children }) {
    const [authUser, setAuthUser] = useState({});
    const [loading, setLoading] = useState(true);

    const signin = (evt, state, handleSuccess, handleErrorState) => {
        evt.preventDefault();

        const { email, password } = state;
        let promise = new Promise(function (resolve, reject) {
            auth.doSignInWithEmailAndPassword(email, password)
            .then((ref) => {
                handleSuccess();
                resolve(ref);
            })
            .catch((error) => {
                handleErrorState(error);
                reject(error);
            });
            });
        return promise;
    };

    const signout = () => {
        return auth.doSignOut();
    };

    const inviteAdmin = (event, email, newUserId, callback) => {
        event.preventDefault();
        db.getInvites().then(res => {
            const currentInvites = res.val();
            let duplicateEmailKey;
            if (currentInvites) {
                Object.keys(currentInvites).forEach(key => {
                    if (currentInvites[key].email === email) {
                        duplicateEmailKey = key;
                    }
                });
            }
            if (duplicateEmailKey) {
                db.removeNewUserFromItemInviteTable(duplicateEmailKey).then(() => {
                    db.inviteNewUser(newUserId, email, callback);
                })
            } else {
                db.inviteNewUser(newUserId, email, callback);
            }
        })
    };

    const onSignUp = (state, ID, handleSetError, callback) => {
        const {
            email,
            password
        } = state;

        const lowercase_email = email.toLowerCase();
        const role = "ADMIN";
        firebase.adminUserSignUp({email, password}).then((res) => {
            if (res && res.data && res.data.success) {
                auth.doSignInWithEmailAndPassword(email, password)
                .then(() => {
                    db.createUser(res.data.authUser.uid, email, lowercase_email, role).then(() => {
                        db.removeNewUserFromItemInviteTable(ID);
                        if (callback) {
                            callback();
                        }
                    })
                    .catch(error => {
                        auth.doSignOut();
                        handleSetError(error);
                    });
                })
                .catch(error => {
                    handleSetError({message: 'You are not authorized'});
                });
            } else {
                handleSetError({message: 'You are not authorized'});
            }
        })
        .catch(error => {
            handleSetError({message: 'You are not authorized'});
        });
    };

    const passwordReset = (email) => {
        let promise = new Promise(function (resolve, reject) {
        auth
            .sendPasswordResetEmail(email)
            .then(() => {
                resolve(`Password Reset Email sent to ${email}`);
            })
            .catch((error) => {
                reject(error);
            });
        });
        return promise;
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setAuthUser(user);
            setLoading(false);
        });
        return unsubscribe;
    }, [authUser]);

    const value = {
        authUser,
        inviteAdmin,
        onSignUp,
        signin,
        signout,
        passwordReset
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}