import React, { useContext, useEffect, useState } from 'react';
import { auth } from '../../firebase';
import ReactBody from 'react-body';
import { AuthContext } from '../../contexts/AuthContext';

const PasswordChangeForm = (props) => {
    const [passwordCurrent, setPasswordCurrent] = useState('');
    const [passwordOne, setPasswordOne] = useState('');
    const [passwordTwo, setPasswordTwo] = useState('');
    const [error, setError] = useState(null);
    const [changeSuccess, setChangeSuccess] = useState(false);
    const [changeProcessing, setChangeProcessing] = useState(false);

    const { authUser } = useContext(AuthContext);

    useEffect(() => {
        if (changeProcessing) {
            auth.doSignInWithEmailAndPassword(authUser.email, passwordCurrent)
            .then(() => {
            auth.doPasswordUpdate(passwordOne)
            .then(() => {
                setPasswordCurrent('');
                setPasswordOne('');
                setPasswordTwo('');
                setError(null);
                setChangeSuccess(true);
                setChangeProcessing(false);
            })
            .catch(error => {
                setError(error);
                setChangeProcessing(false);
            });
            })
            .catch(error => {
                setError(error);
                setChangeProcessing(false);
            });
        }
    }, [changeProcessing])

    const onSubmit = (event) => {
        event.preventDefault();
        setChangeProcessing(true);
    };

    const isInvalid =
        passwordOne !== passwordTwo ||
        passwordOne === '' ||
        passwordCurrent === '';

    return (
        <div className="password-change">
        { !changeSuccess ? 
            <form className="admin-form" id="form-pass" onSubmit={onSubmit}>
            <input
                autoFocus
                value={passwordCurrent}
                onChange={event => setPasswordCurrent(event.target.value)}
                type="password"
                placeholder="Current Password"
            />
            <input
                value={passwordOne}
                onChange={event => setPasswordOne(event.target.value)}
                type="password"
                placeholder="New Password"
            />
            <input
                value={passwordTwo}
                onChange={event => setPasswordTwo(event.target.value)}
                type="password"
                placeholder="Confirm New Password"
            />
            <button className="btn btn-primary" disabled={isInvalid} type="submit">
                { !changeProcessing ?
                <span>change password</span>
                : <span className="btn-loading" /> 
                }
            </button>
            { error && <p className="error">{error.message}</p> }
            </form>
            : <div className="password-change-success auth-success">
                <i className="icon icon-checkmark-circle" />
                <h1>Your password was changed!</h1>
            </div>
        }
        </div>
    );
}

export default PasswordChangeForm;