import React, { useEffect, useContext } from 'react';
import ReactBody from 'react-body';
import AdminControl from './AdminControl';
import AdminLogin from './AdminLogin';
import AdminUsers from './AdminUsers';
import { AuthContext } from '../../contexts/AuthContext';
import { ContentContext } from '../../contexts/ContentContext';

const Admin = (props) => {

  useEffect(() => {
    window.scrollTo(0,0);
  }, [])

  const { authUser, signin } = useContext(AuthContext);
  const { siteContent, setSiteContent } = useContext(ContentContext);
  return (
    <section id="admin">
      <div className="content-intro">
        <div className="content-wrap">
          {!authUser ?
            <div className="admin-container-wrap">
              <h2>Login</h2>
              <AdminLogin onSubmit={signin} siteContent={siteContent} setSiteContent={setSiteContent} />
            </div> :
            <div className="admin-container-wrap">
              <div className="admin-wrap">
                <AdminUsers siteContent={siteContent} setSiteContent={setSiteContent} authUser={authUser} />
                <AdminControl siteContent={siteContent} setSiteContent={setSiteContent} />
              </div>
            </div>
          }
        </div>
      </div>
      <ReactBody className="admin" />
    </section>
  )
}

export default Admin;
