import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import * as routes from 'constants/routes';
import { ContentContext } from '../../contexts/ContentContext';
import Content from '../content/Content';
import ContentImg from '../content/ContentImg';
import ContentAdd from '../content/ContentAdd';
import ContactForm from './ContactForm';
import ReactBody from 'react-body';
import { Helmet } from 'react-helmet-async';
import Lottie from 'lottie-react';
import lottieContentPacks from '../../data/lottie-content-packs-4.json';

const ContentPacks = (props) => {
  
  const { siteContent } = useContext(ContentContext);

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  return (siteContent.contentPacks ?
    <section id="content-packs">
      <Helmet>
        <title>CADD Community - Contentpacks</title>
      </Helmet>
      
      <div className="hero hero-color hero-tertiary">
        <div className="content-wrap">
          <div className="hero-content">
            <Content
              page="contentPacks"
              item="header"
              type="content" />
            <ContactForm />
          </div>
          <div className="hero-lottie">
            <Lottie animationData={lottieContentPacks} />
          </div>
        </div>
      </div>

      <div className="content-packs-sub content-sub">
        <div className="content-wrap">
          <Content
            page="contentPacks"
            item="sub"
            type="content" />
        </div>
      </div>

      <div className="content-packs-posts content-posts content-slim">

        { siteContent.contentPacks.posts ? siteContent.contentPacks.posts.map((post, index) => 
          <div className="content-main" id={`content-${index}`} key={index}>
            <ContentImg
              page="contentPacks"
              item="posts"
              itemIndex={index}
              type="img" />
            <Content
              page="contentPacks"
              item="posts"
              itemIndex={index}
              type="content" />
          </div> 
        ) : null }

        <ContentAdd
          page="contentPacks"
          item="posts"
          type="content" />

      </div>

      <ReactBody className="content-packs header-tertiary" />
    </section>
  : <div className="content-intro">
      <div className="content-loading page-loading" />
      <ReactBody className="content-packs header-tertiary" />
    </div>
  );
};

export default ContentPacks;