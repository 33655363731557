import React, { useContext, useEffect } from 'react';
import * as routes from '../constants/routes';
import { AuthContext } from '../contexts/AuthContext';

const WithAuthorization = BaseComponent => {
  return function WithAuthorization(props) {
    const { authUser } = useContext(AuthContext);

    useEffect(() => {
      if (!authUser) {
        props.history.push(routes.Admin);
      }
    }, [authUser, props.history])

    return authUser ? <BaseComponent page={props.page} item={props.item} itemIndex={props.itemIndex} image={props.image} type={props.type} /> : null;
  }
}

export default WithAuthorization;