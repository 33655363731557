import * as firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';

const config = {
  apiKey: "AIzaSyCoPnhUwEZTETjyuYIyBLEP9pfj4SPYIXE",
  authDomain: "cadd-community-cra.firebaseapp.com",
  databaseURL: "https://cadd-community-cra-default-rtdb.firebaseio.com",
  projectId: "cadd-community-cra",
  storageBucket: "cadd-community-cra.appspot.com",
  messagingSenderId: "532918318003",
  appId: "1:532918318003:web:6e1b5df951545619ae91c6"
  // measurementId: "G-2FS0W13K7N"
}

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const db = firebase.database();
const auth = firebase.auth();
const storage = firebase.storage();
const adminUserSignUp = firebase.functions().httpsCallable('adminUserSignUp');
const removeUserAuthentication = firebase.functions().httpsCallable('removeUserAuthentication');

export {
  db,
  auth,
  storage,
  adminUserSignUp,
  removeUserAuthentication
};

export const adminApp = firebase.initializeApp(config, "secondary");
